import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { Restaurant, WithContext } from 'schema-dts'
import { CityList } from '@shared/api/types/swaggerTypes'

/**
 * @function getLocalBusinessRestaurant - TODO описание
 */

export const getLocalBusinessRestaurant = (rest: TypeMappingRestOutput, cityList: CityList[]) => {
  const weekDaysName = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

  const thisCityObj = cityList.find((el) => el.id === rest.address.city.id)
  const thisCitySlug = thisCityObj?.slug

  const restaurantURL = thisCitySlug ? `https://www.afisha.ru/${thisCitySlug}/restaurant/${rest.url.slug}/` : null

  const result: WithContext<Restaurant> = {
    '@context': 'https://schema.org',
    '@type': 'Restaurant',
    '@id': restaurantURL,
    name: rest.name,
    image: rest.images.main || rest.images.cover['rest1064x1064'],
    description: rest.description.description.afisha || rest.description.description.owner,
    url: restaurantURL,
    sameAs: restaurantURL,
    telephone: rest.contacts.phone.main,
    email: rest.contacts.email,
    // @ts-ignore
    hasMenu: {
      '@type': 'Menu',
      inLanguage: 'Russian',
      hasMenuItem: rest.offers.dishes?.map((item) => ({
        '@type': 'MenuItem',
        name: item.title,
        description: item.description,
        nutrition: item.description,
        image: item.img,
      })),
      hasMenuSection: rest.menu?.map((item) => ({
        '@type': 'MenuSection',
        name: item.type,
        url: item.href,
      })),
    },
    servesCuisine: rest.tags.cuisine?.map((tag) => tag.name),
    acceptsReservations:
      (rest.booking.afisha.active && restaurantURL) ||
      (rest.booking.tomesto.active && rest.booking.tomesto.url) ||
      'Yes',
    priceRange: rest.price.name.short,
    openingHours: Object.entries(rest.schedule.week)?.map(
      ([key, object]) => `${weekDaysName[key]}: ${object.open}-${object.close}`,
    ),
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'RU',
      addressLocality: thisCityObj?.name ? thisCityObj?.name : null,
      streetAddress: rest.address.address,
      contactType: 'Бронирование',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: rest.address.coordinates[1],
      longitude: rest.address.coordinates[0],
    },
  }

  // Если ресторан имеет хороший рейтинг и несколько отзывов то выводим в сниппет инфу об этом
  if (rest.feedback.rating > 6 && rest.feedback.reviews.count >= 1) {
    // @ts-ignore
    result.aggregateRating = {
      '@type': 'AggregateRating',
      itemReviewed: 'Restaurant',
      bestRating: '10',
      ratingValue: rest.feedback.rating,
      reviewCount: rest.feedback.reviews.count,
    }
    // TODO: Добавить разметку для отзывов, но это сделать невозможно пока они погружаются динамически https://developers.google.com/search/docs/appearance/structured-data/review-snippet?hl=ru
  }

  return result
}
