// DEV_URL: http://local.rests.afisha.ru/msk/restaurants/
// TODO: default slug from cookie & rewrite in url path & redirect

import { usePathname, useRouter } from 'next/navigation'
import React, { useEffect } from 'react'

import { useAppDispatch } from '@app/model/store'

import { AppHome } from '@pages/AppHome'
import { AppHomeSEO } from '@pages/AppHome/SEO'
import { initialAppHomePageAC } from '@pages/AppHome/model/createActions'

import { PageLayout } from '@app/PageLayout'

import { API } from '@shared/api'
import { getDefaultRequestDataOnServer } from '@shared/api/getDefaultRequestDataOnServer'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'
import getGlobalProps from '@shared/api/mappingGlobalProps'
import Loading from './loading'
import { GetServerSideProps, GetServerSidePropsContext } from 'next'
import { resetState } from '@pages/AppHome/model/reducer'

const CURRENT_PAGE = 'app_home'

async function getProps(context) {
  const { city_slug } = context.params

  const global_data = await getGlobalProps({ city_slug, current_page: CURRENT_PAGE })
  const thisCityId = global_data?.this_city?.id
  const defaultRequestData = getDefaultRequestDataOnServer(thisCityId)

  // *** rests ***
  const special_offer = await API.restaurant_list({
    // @ts-ignore
    params: { city: thisCityId, has_special: true, page: 1, page_size: 16 },
    ...defaultRequestData,
  })
  const by_rating = await API.restaurant_list({
    // @ts-ignore
    params: { city: thisCityId, ordering: '-rating', page: 1, page_size: 16 },
    ...defaultRequestData,
  })

  // Только для МСК
  const isMsk = thisCityId === 2
  const booking_rests = await new Promise((resolve) => {
    if (!isMsk) resolve(null)

    API.selection_restaurants({
      params: { city_id: thisCityId, page: Math.floor(Math.random() * 8) + 1, page_size: 12 },
      path: 'restorany-kotorye-nado-bronirovat-pryamo-sejchas',
    })
      .then((res) => {
        const restsWithoutTomesto = res.results.filter(
          (rest) => rest.booking.afisha.active && !rest.booking.tomesto.active,
        )
        resolve(restsWithoutTomesto.slice(0, 8) || null)
      })
      .catch((err) => resolve(null))
  })

  // *** news ***
  const all_data = await API.news_list({
    // @ts-ignore
    params: { city_id_in: thisCityId, page: 1, page_size: 18 },
    ...defaultRequestData,
  })

  const plani_na_nedelu = await API.news_list({
    // @ts-ignore
    params: { city_id_in: thisCityId, page: 1, page_size: 1, variety_in: 'plani-na-nedelu' },
    ...defaultRequestData,
  })

  // *** selections ***
  const afisha_selected = await API.selection_list({
    params: { city_id: thisCityId, page: 1, page_size: 8 },
    ...defaultRequestData,
  })

  // * SEO
  const seo_read = await API.seo_read({
    path: `index/?city_id=${thisCityId}`,
    ...defaultRequestData,
  })

  const filterAllNewsData = (thisAllData) => {
    // ! TODO: необходимо исследовать (и возможно упростить логику)
    //    почему пришлось добавить дополнительную проверку
    //    на наличие type.slug

    try {
      return thisAllData.results.filter((newsItem) => {
        return newsItem?.type?.slug ? newsItem.type.slug !== 'plani-na-nedelu' : false
      })
    } catch (e) {
      return false
    }
  }

  return {
    global_data,
    data: {
      home_rests: {
        special_offer: special_offer.results,
        by_rating: by_rating.results,
        booking_rests: booking_rests,
        feature_rests: global_data.city_read.feature_restaurants,
        tags: global_data.city_read.tag_on_main,
        //@ts-ignore
        quick_filters: global_data.city_read.quick_filters,
      },

      home_news: {
        all_data: filterAllNewsData(all_data),
        plani_na_nedelu: plani_na_nedelu.results?.length ? plani_na_nedelu.results[0] : null,
      },
      afisha_selected: afisha_selected.results,
    },
    seo_read: seo_read,
  }
}
export const getServerSideProps = async (context) => {
  const initData = await getProps(context)

  return {
    props: initData,
  }
}

export default function Page(props) {
  const dispatch = useAppDispatch()
  const seoData = props?.seo_read
  const currentPage = usePathname()
  const router = useRouter()

  useEffect(() => {
    dispatch(initialAppHomePageAC(props.data))

    return () => {
      dispatch(resetState())
    }
  }, [props])

  useEffectOnce(() => {
    const urlCitySLug = currentPage.split('/').filter(Boolean)[0]
    const isSameCity = urlCitySLug === props.global_data.this_city.slug
    if (!isSameCity) {
      router.push(`/${props.global_data.this_city.slug}/restaurants/`, { scroll: false })
    }
  })
  return (
    <>
      <AppHomeSEO seoData={seoData} globalData={props.global_data} homeData={props.data} />

      <PageLayout globalData={props.global_data} currentPage={CURRENT_PAGE}>
        <AppHome {...props} />
      </PageLayout>
    </>
  )
}
