
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[city_slug]/restaurants",
      function () {
        return require("private-next-pages/[city_slug]/restaurants/index.page.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[city_slug]/restaurants"])
      });
    }
  