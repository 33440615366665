/**
 * @function splitEvenOdd - TODO описание
 */

export const splitEvenOdd = (array) => {
  const evenArray = []
  const oddArray = []
  array.forEach((value, index) => {
    if (index % 2) {
      evenArray.push(value)
    } else {
      oddArray.push(value)
    }
  })
  return { evenArray, oddArray }
}
