import React, { FC } from 'react'
import Head from 'next/head'
import { getLocalBusinessRestaurant } from '@shared/lib/helpers/SEO/getRestaurant'
import { ItemList, WithContext } from 'schema-dts'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { CityList } from '@shared/api/types/swaggerTypes'
interface Props {
  restaurants: TypeMappingRestOutput[]
  cityList: CityList[]
}

const CarouselRestaurantsJsonLd: FC<Props> = ({ restaurants, cityList }) => {
  const getUniqueRests = (restaurants) => {
    if (!restaurants) return
    const uniqueRests = new Set()
    restaurants.forEach((rest) => uniqueRests.add(rest.id))
    return Array.from(uniqueRests).map((id) => restaurants.find((rest) => rest.id === id))
  }

  const carouselJsonLd: WithContext<ItemList> = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: getUniqueRests(restaurants)?.map((restData, index) => ({
      '@type': 'ListItem',
      '@id': `https://www.afisha.ru${restData.url.path}-carousel-item`,
      position: index + 1,
      item: getLocalBusinessRestaurant(restData, cityList),
    })),
  }

  return (
    <Head>
      {restaurants && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(carouselJsonLd) }}></script>
      )}
    </Head>
  )
}

export default CarouselRestaurantsJsonLd
